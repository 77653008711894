import callBackendAPI from 'services/api/helpers/backendAPI'
import { AvailableCurrencies, AvailableCountries, AvailableLanguages } from 'globalTypes'
import { CurrencyObj } from 'services/types'

/******************
 * Fetch from API *
 ******************/

export type CreditCheckKeyEnum =
  | 'general-company-information'
  | 'key-financial-figures'
  | 'relevant-financial-ratios'
  | 'owners'
  | 'management'
  | 'map'

export type CompanyInfoKeyEnum =
  | 'company-name'
  | 'company-id'
  | 'email'
  | 'phone'
  | 'webpage'
  | 'address'
  | 'ad-protection'
  | 'registered-for-vat'
  | 'ean'
  | 'company-type'
  | 'date-of-establishment'
  | 'founders_auditors'
  | 'auditors'
  | 'auditor_selected'
  | 'financial_year'
  | 'bank'
  | 'share-capital'
  | 'powers-to-bind'
  | 'purpose'
  | 'number-of-employees'
  | 'industry'
  | 'production-units'
  | 'listed'
  | 'secondary-names'
  | 'status'
  | 'internal-id'
  | 'property'

export type MonitorTableEnum =
  | 'companyName'
  | 'localId'
  | 'creditMax'
  | 'score'
  | 'country'
  | 'status'
  | 'profitLoss'
  | 'equity'
  | 'employeeInterval'
  | 'currency'
  | 'companyType'
  | 'solidity'
  | 'liquidity'
  | 'dateOfIncorporation'
  | 'internalId'
  | 'capital'
  | 'fraudIndicators'

export type CreditCheckSidebarEnum =
  | 'estimation'
  | 'explanation'
  | 'recommendation'
  | 'company-highlights'
  | 'performance'
  | 'credit-policy'

export enum FraudIndicatorEnum {
  DuplicateStatements = 'duplicate-statements',
  MultipleCompaniesAtSameAddress = 'multiple-companies-at-the-same-address',
  MaxNumberOfNameChanges = 'max-number-of-name-changes',
  ReverseAuditorBankruptcyCheck = 'reverse-auditor-bankruptcy-check',
  FinancialStatementAbnormalities = 'financial-statement-abnormalities',
  ProfitVsEmployeesAbnormalities = 'profit-vs-employees-abnormalities',
  BenfordsLaw = 'benfords-law',
  ExcludeAddresses = 'exclude-addresses',
  ExcludeCompanies = 'exclude-companies',
  ExcludeAuditors = 'exclude-auditors',
  ExcludeOwners = 'exclude-owners',
}

export type AvailableCountriesCapital = 'DK' | 'SE' | 'NO' | 'UK' | 'FI' | 'DE'

export type FiltersType = any

export type CreditCheckType = {
  key: CreditCheckKeyEnum
  visible: boolean
}
export type CompanyInfoType = {
  key: CompanyInfoKeyEnum
  visible: boolean
}
export type MonitorTableType = {
  key: MonitorTableEnum
  visible: boolean
}

export type CreditCheckSidebarType = {
  key: CreditCheckSidebarEnum
  visible: boolean
}

export type FraudIndicatorType = {
  key: FraudIndicatorEnum
  visible: boolean
}

type ResponseData = {
  data: {
    language: AvailableLanguages
    credit_check: CreditCheckType[]
    company_info: CompanyInfoType[]
    monitor_table: MonitorTableType[]
    fraud_indicators: FraudIndicatorType[]
    credit_check_sidebar: CreditCheckSidebarType[]
    selected_country: AvailableCountries
    selected_currency: CurrencyObj
    filters: FiltersType
    export_options_filtering: null
    export_options_monitoring: null
  }
}

const getUserSettings = () => {
  return callBackendAPI('/user/settings', {
    method: 'get',
  }).then(({ data }: ResponseData) => {
    return {
      monitorTableFields: data.monitor_table.filter((x) => x.visible).map((x) => x.key),
      creditCheckFields: data.credit_check.map((x) => x.key),
      companyInfoFields: data.company_info.map((x) => x.key),
      fraudIndicators: data.fraud_indicators.map((x) => x.key),
      creditCheckSidebarPos: data.credit_check_sidebar.map((x) => x.key),
      selectedCurrency: {
        currency: data.selected_currency.currency,
        country: data.selected_currency.country,
      },
      selectedCountry: data.selected_country,
      exportOptionsFiltering: data.export_options_filtering,
      exportOptionsMonitoring: data.export_options_monitoring,
      language: data.language,
    }
  })
}

export default getUserSettings
