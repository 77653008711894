import { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { classes } from '../../../../styles/monitor-table'
import EditSelectedList from '../../../../scenes/MonitorTable/EditSelectedList'
import TableName from '../../../../scenes/MonitorTable/TableName'
import TableActions from '../../../../scenes/MonitorTable/TableActions'
import { ReduxRootState } from 'store_deprecated/types'
import { SelectedType } from 'components-new/data-display/Table/Table.types'
import MonitoringTableRowActions from './widgets/MonitoringTableRowActions/MonitoringTableRowActions'
import MonitoringTableFilters from './widgets/MonitoringTableFilters'
import { useSelector } from 'react-redux'
import { AvailableCurrencies } from 'globalTypes'

export type MonitoringTableToolbarProps = {
  listName: string
  selectedCurrency: AvailableCurrencies
  selectedRows: SelectedType[]
  refetch: () => void
  handleFilters: (category: string, key: string) => void
  handleSearch: (searchTerm: string) => void
  tableFilters: any
  isLoading: boolean
  listId: number
}

const MonitoringTableToolbar = ({
  listName,
  selectedCurrency,
  selectedRows,
  refetch,
  listId,
  handleFilters,
  handleSearch,
  tableFilters,
  isLoading,
}: MonitoringTableToolbarProps) => {
  const { selectedList } = useSelector(
    (state: ReduxRootState) => state.risika.newRiskMonitoring
  )
  const [isEditing, setIsEditing] = useState(false)

  return (
    <Box sx={classes.tableTopMenu}>
      {selectedRows.length > 0 ? (
        <MonitoringTableRowActions
          selectedCurrency={selectedCurrency}
          refetch={refetch}
          rows={selectedRows}
        />
      ) : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          sx={classes.listName}
        >
          <Stack direction={'row'}>
            {isEditing ? (
              <EditSelectedList
                selectedList={selectedList}
                setIsEditing={setIsEditing}
                name={listName}
              />
            ) : (
              <TableName setIsEditing={setIsEditing} listName={listName} />
            )}
          </Stack>
          <Box>
            <MonitoringTableFilters
              handleFilters={handleFilters}
              handleSearch={handleSearch}
              tableFilters={tableFilters}
              isLoading={isLoading}
              listId={listId}
            />
          </Box>
          <TableActions
            selectedCurrency={selectedCurrency}
            renderTableSettings={false}
            refetch={refetch}
          />
        </Stack>
      )}
    </Box>
  )
}

export default MonitoringTableToolbar
