import { useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { monitorExport } from 'services/api'

const useMonitorExport = (listID: number, currency: string) => {
  const exportData = useQuery<any[], AxiosError<Error>>(
    ['monitorExport', listID],
    () => monitorExport(listID, currency),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  )
  return { ...exportData }
}

export { useMonitorExport }
