import * as React from 'react'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import intl from 'localization/components'
import { Dropdown } from '../..'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material'
import { StoreExportOptions } from 'components'
import withRestrictions from '../withRestrictions'
import { downloadAs, downloadFormat } from 'services/export'
import { useAccountStatus } from 'services/queries'
import SelectFieldsContent from './SelectedFieldsContent'
import useTotangoTracking from 'utils/totangoTracking'
import { RISIKA_PRICING_PAGE } from 'configs/constants/system'
import { classes } from './ButtonRequestMonitorExport.styles'
import { ButtonQuaternary, ButtonSecondary } from 'components-new'
import { useMonitorExport } from 'services/queries/useDataToExport'
import { translateAndOrderCompanyData } from './utils'

// Mapping
const mapExportFields = (row) => ({
  companyId: row.local_organization_id?.id ?? '',
  companyName: row.company_name ?? '',
  companyType: row.company_type ?? '',
  status: row.status ?? '',
  dateOfIncorporation: row.date_of_incorporation ?? '',
  score: row?.score || row?.risk_assessment || '',
  creditMax: row.credit_max ?? '',
  phone: row.phone_number ?? '',
  street: row.address?.street ?? '',
  number: row.address?.number ?? '',
  coname: row.address?.coname ?? '',
  zipcode: row.address?.zipcode ?? '',
  city: row.address?.city ?? '',
  municipality: row.address?.municipality ?? '',
  postdistrict: row.address?.postdistrict ?? '',
  country: row.address?.country ?? '',
  currency: row.financial_key_figures?.currency ?? '',
  equity: row.financial_key_figures?.equity ?? '',
  profitLoss: row.financial_key_figures?.profit_loss ?? '',
  solidity: row.financial_key_figures?.solidity ?? '',
  liquidity: row.financial_key_figures?.liquidity ?? '',
  industryCode: row.industry_code ?? '',
  industryDescription: row.industries.main_industry?.description ?? '',
  employees: row.employee_interval ?? '',
  internalId: row.internal_id ?? '',
  startDate: row.financial_key_figures.period.start ?? '',
  endDate: row.financial_key_figures.period.end ?? '',

  capital: row.capital ?? '',
  EBITDA: row.financial_key_figures.ebitda ?? '',
  ebit: row.financial_key_figures.ebit ?? '',
  ordinaryProfit: row.financial_key_figures.ordinary_profit ?? '',
  shortTermDebt: row.financial_key_figures.short_term_debt ?? '',
  longTermDebt: row.financial_key_figures.long_term_debt ?? '',
  shortTermTradePayables: row.financial_key_figures.short_term_trade_payables ?? '',
  shortTermReceivablesFromSalesAndServices:
    row.financial_key_figures.short_term_receivables_from_sales_and_services ?? '',
})
export const ButtonRequestMonitorExport = ({
  listID = 0,
  selectedCurrency,
  filename,
  render,
  disabled,
  text = <FormattedMessage id={intl.exportButton('text')} />,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const accountStatusQuery = useAccountStatus()

  const { data: exportData } = useMonitorExport(listID, selectedCurrency)

  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState({})
  const [selectedFields, setSelectedFields] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [filetype, setFiletype] = React.useState('')
  const { trackEvent } = useTotangoTracking()
  const reactIntl = useIntl()
  const messages = reactIntl.messages

  const now = moment().format('YYYY-MM-DD hh-mm-ss')
  const exportFilename = filename ?? `Risika export ${now}`

  // Set proper lang labels and also the order

  const parseDataToExport = () => {
    const mappedExportFields = exportData?.map(mapExportFields)

    const titledData = translateAndOrderCompanyData(
      mappedExportFields,
      accountStatusQuery.data?.subscription_plan,
      messages
    )
    setData(titledData)
  }

  /**
   * Determine which file type should be
   * downloaded and delegate the work.
   * Fires on opening dropdown menu (starting download procedure)
   *
   * @param {string} fileType The file type to export.
   */
  const handleFileDownload = async (fileFormat) => {
    setLoading(true)
    setFiletype(fileFormat)
    setOpen(true)
    parseDataToExport()
  }

  const keepSelectedFields = (companiesArr) =>
    companiesArr.map((companyObj) => {
      const newCompanyObj = {}
      Object.keys(companyObj).forEach((field) => {
        if (selectedFields.includes(field)) {
          newCompanyObj[field] = companyObj[field]
        }
      })
      return newCompanyObj
    })

  const handleFinishDownload = () => {
    trackEvent('Monitoring', 'Export List')
    const download = downloadAs(filetype)
    setOpen(false)

    const readyData = keepSelectedFields(data)

    download(exportFilename, readyData)
    if (readyData?.length) {
      enqueueSnackbar(<FormattedMessage id={intl.snackbar('export-success')} />, {
        variant: 'success',
      })
    } else {
      enqueueSnackbar(<FormattedMessage id={intl.snackbar('export-failed')} />, {
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const handleClose = () => {
    setOpen(false)
    setLoading(false)
  }

  const RestrictedDropdownMenu = withRestrictions('allow_monitoring_export', {
    title: <FormattedMessage id={intl.restriction('export-title')} />,
    content: <FormattedMessage id={intl.restriction('export-description')} />,
    button: {
      text: <FormattedMessage id={intl.restriction('export-button')} />,
      link: { RISIKA_PRICING_PAGE },
    },
  })(Dropdown)

  return (
    <>
      <RestrictedDropdownMenu
        render={(open) => render(open, loading)}
        text={text}
        disabled={disabled}
        loading={loading}
      >
        <MenuItem onClick={() => handleFileDownload(downloadFormat.EXCEL)}>
          Excel
        </MenuItem>
        <MenuItem onClick={() => handleFileDownload(downloadFormat.CSV)}>CSV</MenuItem>
      </RestrictedDropdownMenu>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={classes.header}>
          <Typography variant="h3">
            <FormattedMessage id={intl.advancedFilters('select-columns')} />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <SelectFieldsContent
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
          />
        </DialogContent>
        <DialogActions
          sx={{
            px: 5,
            py: 4,
          }}
        >
          <Stack
            width={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gap={2}
          >
            <Box flexGrow={1}>
              <StoreExportOptions
                selectedFields={selectedFields?.sort()}
                setSelectedFields={setSelectedFields}
                persistedLocationKey="exportOptionsMonitoring"
              />
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              flexGrow={1}
              spacing={2}
            >
              <ButtonQuaternary onClick={handleClose}>
                {<FormattedMessage id={intl.confirmAction('cancel')} />}
              </ButtonQuaternary>
              <ButtonSecondary
                disabled={!Object.keys(selectedFields).length}
                onClick={handleFinishDownload}
              >
                {<FormattedMessage id={intl.restriction('export-title')} />}
              </ButtonSecondary>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ButtonRequestMonitorExport
