import { theme } from 'styles-new/mui5-transition/theme'
import { monitorOptions } from './model'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import numeral from 'numeral'

/**
 * Calculate the weight of the changes for the company.
 *
 * @param {obj} company
 */
const calculateWeightForCompany = (company) =>
  company.changes ? company.changes.reduce((acc, cur) => acc + cur.weight, 0) : 0

/**
 * Sort companies by their weight (Risk Monitoring)
 */
export const sortCompaniesByWeight = (a, b) => {
  const weightA = calculateWeightForCompany(a)
  const weightB = calculateWeightForCompany(b)
  if (weightA > weightB) {
    return -1
  }
  if (weightA < weightB) {
    return 1
  }
  return 0
}

/**
 * Risk category definitions.
 */
export const riskCategories = {
  10: theme.palette.success.dark,
  9: theme.palette.success.dark,
  LOW: theme.palette.success.main,
  8: theme.palette.success.main,
  7: theme.palette.success.main,
  MEDIUM: theme.palette.warning.main,
  6: theme.palette.warning.main,
  5: theme.palette.warning.main,
  4: theme.palette.warning.dark,
  3: theme.palette.warning.dark,
  HIGH: theme.palette.warning.dark,
  2: theme.palette.error.main,
  1: theme.palette.error.main,
  '-': theme.palette.grey[500],
}
// import { sortCompaniesByWeight } from './calculateByWeåight'

export const formatData = (data) => {
  return (
    data
      ?.sort(sortCompaniesByWeight)
      ?.map((x) => ({
        companyName: x.company_name,
        score: x.score,
        currency: x.financial_key_figures.currency,
        localId: x.local_organization_id,
        country: x.local_organization_id.country,
        status: x.status,
        address: x.address,
        fraudIndicators: x.fraud_indicators,
        capital: x.capital
          ? `${numeral(x?.capital).format('0,0')} ${x?.financial_key_figures?.currency}`
          : 'N/A',
        changes: x?.changes ?? [],
        statusCode: x.status_code,
        creditMax: x.credit_max,
        companyType: x.company_type,
        profitLoss: x.financial_key_figures.profit_loss,
        solidity: x.financial_key_figures.solidity,
        liquidity: x.financial_key_figures.liquidity,
        industryCode: x.industry_code,
        dateOfIncorporation: x.date_of_incorporation,
        equity: x.financial_key_figures.equity,
        isCreditPolicy: x?.credit_policy?.allowed,
        creditPolicyConflicts: x?.credit_policy?.conflicts,
        riskAssessmentCode: x?.risk_assessment_code,
        riskAssessment: x?.risk_assessment,
        employeeInterval: x?.employee_interval?.startsWith('1000-')
          ? '1000+'
          : x.employee_interval,
        internalId: x.internal_id,
      }))
      .filter((z) => z.companyName !== null) ?? []
  )
}

export const getCompanyName = (row) => row.original.companyName

export const getAddress = (row) => {
  const { city, municipality } = row.original.address
  if (city) {
    return city?.includes(municipality) ? city : `${city} (${municipality})`
  }
  return null
}

export const getLocalId = (row) => row.original.localId
export const getPolicyValues = (row) => {
  return {
    isCreditPolicy: row.original.isCreditPolicy,
    conflicts: row.original.creditPolicyConflicts,
  }
}

export const getCurrency = (row) => row.original.currency

export const getCompanyTypeShort = (row) => row.original.companyType?.short

export const shouldHideScore = (row) => {
  const companyTypesToHide = ['ENK']
  const companyType = getCompanyTypeShort(row)
  return companyTypesToHide.includes(companyType)
}

export const riskMonitoringFormatError = (error) => {
  const errorTranslationKeys = {
    402: 'cannot-monitor-that-many-companies',
    400: 'invalid-request',
    // this can be expanded as and when required
  }

  if (error.response?.data?.error) {
    return error.response.data.error
  }

  if (errorTranslationKeys[error.response?.status]) {
    return (
      <FormattedMessage
        id={intl.riskMonitoring(errorTranslationKeys[error.response?.status])}
      />
    )
  }

  return error.message
}

/**
 * Build the options object from another object containing
 * whether the specified option has been selected.
 *
 * @example
 * buildOptions({ status: true, address: false, ...}) => {
 *  { status: { key: 'status', value: true, important: true }},
 *  { address: { key: 'address', value: false, important: false }}
 * }
 *
 *
 * @param {object} options The object containing the options.
 * @returns {object} The new options object.
 */

export const buildOptions = (options) => {
  return Object.keys(monitorOptions).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: {
        ...monitorOptions[cur],
        value: options[cur],
      },
    }),
    {}
  )
}

// /************************
//  * Configration Helpers *
//  ************************/

/**
 * Turn the options object into an array.
 *
 * @example
 * optionsToArray({ status: { key: 'status', value: true, important: true }, ...}) => [
 *   { key: 'status', value: true, important: true },
 *   ...
 * ]
 *
 * @param {object} optionsObject The options object.
 * @returns {object[]} The options as an array.
 */
export const optionsToArray = (optionsObject) => {
  return Object.entries(optionsObject).map(([key, value]) => value)
}

/**
 * Converts and options array into an object with all the options
 * you can be monitoring (as the key) and whether that option is monitored (value).
 *
 * @example
 *  fromOptionsArray([{ status: { key: 'status', value: true, ... }, ...}]) => {
 *    status: true,
 *    ...
 *  }
 *
 * @param {Object[]} optionsArray The options array.
 * @returns {Object} Create object containing whether the given option has been selected.
 */
export const fromOptionsArray = (optionsArray) => {
  return Object.fromEntries(optionsArray.map((x) => [x.key, x.value]))
}
