import React from 'react'
import { useSelector } from 'react-redux'
import {
  IconButton,
  CircularProgress,
  Tooltip,
  Paper,
  useMediaQuery,
  Box,
} from '@mui/material'
import { useRemoveList } from 'services/queries'
import DeleteSelectedList from './DeleteSelectedlist'
import { ImportCompanies, ButtonRequestMonitorExport } from 'components'
import HandleVisibility from './HandleVisibility'
import TableSettings from './TableSettings'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { classes } from '../../../styles/table-actions'
import useApiIds from 'pages/risk-monitoring/hooks/useApiIds'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import DownloadIcon from '@mui/icons-material/Download'
import { standardIcon } from 'styles-new/global/components'

const TableActions = ({ renderTableSettings = true, selectedCurrency, refetch }) => {
  const { mutate: removeList } = useRemoveList()
  const isPhone = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const { isCurrentUserSameAsListOwner } = useApiIds()
  const { lists, selectedList } = useSelector((state) => state.risika.newRiskMonitoring)
  const [open, setOpen] = React.useState(false)
  const disabled = !isCurrentUserSameAsListOwner

  const content = (
    <Box sx={classes.container}>
      {renderTableSettings && <TableSettings />}
      <ImportCompanies selectedCurrency={selectedCurrency} refetch={refetch}>
        {(open) => (
          <Tooltip
            title={
              <FormattedMessage id={intl.riskMonitoringNew('add-companies-button')} />
            }
          >
            <IconButton data-cy="import-monitoring" color="primary" onClick={open}>
              <InsertDriveFileIcon sx={standardIcon} />
            </IconButton>
          </Tooltip>
        )}
      </ImportCompanies>
      <ButtonRequestMonitorExport
        listID={selectedList}
        selectedCurrency={selectedCurrency}
        render={(open, loading) => (
          <Tooltip
            title={
              <FormattedMessage id={intl.riskMonitoringNew('export-companies-button')} />
            }
          >
            <IconButton color="primary" onClick={open}>
              {!loading ? (
                <DownloadIcon sx={standardIcon} />
              ) : (
                <CircularProgress sx={classes.buttonProgress} size={24} />
              )}
            </IconButton>
          </Tooltip>
        )}
      />
      <HandleVisibility disabled={disabled} />
      <Box sx={classes.divider} />
      <DeleteSelectedList
        deleteList={() => removeList(selectedList)}
        disabled={disabled}
        selectedListName={lists[selectedList]?.name}
      />
    </Box>
  )

  if (isPhone) {
    return (
      <Box>
        <IconButton onClick={() => setOpen((isOpen) => !isOpen)}>
          {!open ? <FontAwesomeIcon icon={faBars} /> : <FontAwesomeIcon icon={faTimes} />}
        </IconButton>
        {open ? <Paper sx={classes.paper}>{content}</Paper> : null}
      </Box>
    )
  }

  return content
}

export default TableActions
