import { Typography, TypographyOwnProps, Box } from '@mui/material'
import { Check as CheckIcon, PriorityHigh as ErrorIcon } from '@mui/icons-material'
import { compliantStyle } from '../../../../../components-new/data-display/Table/Table.styles'

type FraundIndicatorCellProps = {
  sx?: TypographyOwnProps['sx']
  cell: any
  row: any
}

const FraundIndicatorCell = ({ sx, cell, row }: FraundIndicatorCellProps) => {
  let DisplayContent = () => (
    <Typography variant="body2" sx={sx}>
      {cell.value}
    </Typography>
  )

  const isCompliant = row?.original?.fraudIndicators >= 0.5
  const CompliantIcon = isCompliant ? CheckIcon : ErrorIcon // TODO here

  DisplayContent = () => (
    <Typography sx={{ ...compliantStyle(isCompliant ? 'COMPLIANT' : '') }}>
      <CompliantIcon sx={{ fontSize: '2.4rem', padding: '0.4rem' }} />
    </Typography>
  )

  return (
    <Box display="flex">
      <DisplayContent />
    </Box>
  )
}

export default FraundIndicatorCell
