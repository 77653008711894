import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'
import { Typography, Stack } from '@mui/material'
import { ActionDialog, Hide } from 'components'
import ListActionDialog from 'pages/risk-monitoring/scenes/MonitorTable/RowActions/ListActionDialog'
import {
  submitRemoveCompanyFromRiskMonitoringList,
  submitAddToMonitoringList,
  submitFetchListDataById,
  setText,
} from 'store_deprecated'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { isNil } from 'ramda'
import updateNotes_dangerous from 'services/api/endpoints/risikaAPI/notes/resources/updateNotes_dangerous'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEraser } from '@fortawesome/free-solid-svg-icons'
import { ButtonDestructive, ButtonTertiary } from 'components-new'
import DeleteIcon from '@mui/icons-material/Delete'
import { ReduxRootState } from 'store_deprecated/types'
import { Company } from 'components-new/data-display/Table/Table.types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { AvailableCurrencies, LocalId } from 'globalTypes'

const main = {
  px: 5,
  py: 3,
  bgcolor: 'primary.dark',
}

export const classes = {
  main,
}

export type CompanyRow = {
  id: string
  country: string
  original: Company
}

export type RowActionsProps = {
  rows: CompanyRow[]
  selectedCurrency: AvailableCurrencies
  refetch: () => void
}

export type SelectedCompanies = { id: string; country: string; hash?: string }[]

const extractLocalIds = (companyRows: CompanyRow[]) => {
  return companyRows?.map(
    (company) => company.original.local_organization_id
  ) as LocalId[]
}

const extractInternalIds = (companyRows: CompanyRow[]) => {
  return companyRows.filter((company) => company.original.internal_id !== null)
}

const RowActions = ({ rows, selectedCurrency, refetch }: RowActionsProps) => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const { lists, selectedList } = useSelector(
    (state: ReduxRootState) => state.risika.newRiskMonitoring
  )
  const { text } = useSelector((state: ReduxRootState) => state.risika.newRiskMonitoring)

  React.useEffect(() => {
    if (text !== '' && !isNil(text)) {
      enqueueSnackbar(
        <FormattedMessage
          id={intl.riskMonitoringNew(text)}
          values={{
            count: rows.length,
          }}
        />
      )
      dispatch(setText(''))
    }
  }, [rows, text, dispatch, enqueueSnackbar])

  const handleMove = (listId: string, selectedCompanies: SelectedCompanies) => {
    dispatch(
      submitAddToMonitoringList(
        listId,
        selectedCompanies,
        'items-moved',
        'items-failed-to-move'
      )
    )
    const isMoving = true
    handleDelete(selectedCompanies, isMoving)
  }

  const handleCopy = (listId: string, selectedCompanies: SelectedCompanies) => {
    dispatch(
      submitAddToMonitoringList(
        listId,
        selectedCompanies,
        'items-copied',
        'items-failed-to-copy'
      )
    )
  }

  const handleDelete = (rows: SelectedCompanies, isMoving: boolean) => {
    if (rows.length > 50) {
      dispatch(setText('deleting-companies-wait'))
    }
    dispatch(
      submitRemoveCompanyFromRiskMonitoringList(selectedList, rows, isMoving, refetch)
    )
  }

  // const handleRejectSelectedComapnies = () => {
  //   const entriesByCountry = {}
  //   const fieldType = 'exclude_companies'
  //   rows?.forEach(({ original: row }) => {
  //     const country = row?.country?.toLowerCase()
  //     Object.assign(entriesByCountry, {
  //       ...entriesByCountry,
  //       [country]: entriesByCountry?.[country]
  //         ? [
  //             ...entriesByCountry?.[country],
  //             { id: row.localId.id, name: row.companyName },
  //           ]
  //         : [{ id: row.localId.id, name: row.companyName }],
  //     })
  //   })
  //   Object.keys(entriesByCountry)?.forEach((country) => {
  //     const newValues = [
  //       ...(policyForm?.[country]?.policyValues?.[fieldType]?.length
  //         ? policyForm?.[country]?.policyValues?.[fieldType]
  //         : []),
  //       ...entriesByCountry?.[country],
  //     ]
  //     dispatch(
  //       updatePolicyValues({
  //         tabSelected: country,
  //         fieldType,
  //         // Add the new values to redux and remove dublicates
  //         // Limit 500
  //         values: take(
  //           500,
  //           Array.from(new Set(newValues.map((a) => a.id))).map((id) => {
  //             return newValues.find((a) => a.id === id)
  //           })
  //         ),
  //         isSingle: false,
  //       })
  //     )
  //   })
  //   Object.keys(entriesByCountry)?.forEach((country) => {
  //     creditPolicySet(country)
  //       .then((res) => {
  //         dispatch(flushCachedPages())
  //         if (entriesByCountry[country].length > 500) {
  //           enqueueSnackbar(
  //             <FormattedMessage
  //               id={intl.creditPolicy('too-many-values')}
  //               values={{
  //                 country: <FormattedMessage id={intl.creditPolicy(country)} />,
  //               }}
  //             />
  //           )
  //         }
  //         enqueueSnackbar(
  //           <FormattedMessage
  //             id={intl.creditPolicy('save-success')}
  //             values={{
  //               country: <FormattedMessage id={intl.creditPolicy(country)} />,
  //             }}
  //           />
  //         )
  //       })
  //       .catch((err) => {
  //         enqueueSnackbar(
  //           <FormattedMessage
  //             id={intl.creditPolicy('save-failed')}
  //             values={{
  //               country: <FormattedMessage id={intl.creditPolicy(country)} />,
  //             }}
  //           />
  //         )
  //       })
  //   })
  // }

  const removeInternalIds = (localIds: LocalId[]) => {
    const notes = localIds.map((localId) => ({
      data: {
        _internal_id: null,
      },
      localId,
    }))

    updateNotes_dangerous(notes).then(() => {
      dispatch(submitFetchListDataById(selectedList, selectedCurrency))
    })
  }

  return (
    <Stack direction="row" spacing={2} sx={classes.main}>
      {/* <ConditionallyRender */}
      {/*  condition={handleComponentRenderRestrictions({ */}
      {/*    component: 'monitor_add_to_policy_list_button', */}
      {/*    plan: accountStatusQuery.data?.subscription_plan, */}
      {/*  })} */}
      {/*  when={ */}
      {/*    <ButtonTertiary onClick={handleRejectSelectedComapnies}> */}
      {/*      {<FormattedMessage id={intl.riskMonitoring('reject-companies')} />} */}
      {/*    </ButtonTertiary> */}
      {/*  } */}
      {/* /> */}
      <ListActionDialog
        handleAction={handleMove}
        selectedCompanies={extractLocalIds(rows)}
        lists={lists}
        title={'move-to-another-list-title'}
        render={(open: () => void) => (
          <ButtonTertiary onClick={open}>
            <FormattedMessage id={intl.riskMonitoringNew('move-button-text')} />
          </ButtonTertiary>
        )}
      />
      <ListActionDialog
        handleAction={handleCopy}
        selectedCompanies={extractLocalIds(rows)}
        lists={lists}
        title={'copy-to-another-list-title'}
        render={(open: () => void) => (
          <ButtonTertiary onClick={open}>
            <FormattedMessage id={intl.riskMonitoringNew('copy-button-text')} />
          </ButtonTertiary>
        )}
      />
      <ActionDialog
        title={
          <FormattedMessage
            id={intl.riskMonitoringNew('delete-companies-title')}
            values={{
              count: rows.length,
            }}
          />
        }
        content={(close: () => void) => (
          <Typography>
            <FormattedMessage
              id={intl.riskMonitoringNew('are-you-sure-delete-entries')}
              values={{
                count: rows.length,
              }}
            />
          </Typography>
        )}
        maxWidth="sm"
        fullWidth={true}
        action={() => handleDelete(extractLocalIds(rows) as SelectedCompanies, false)}
        agreeText={<FormattedMessage id={intl.confirmAction('delete')} />}
        danger
        render={(open: () => void) => (
          <ButtonDestructive
            data-cy="delete-all-selected"
            startIcon={<DeleteIcon />}
            onClick={open}
          >
            <FormattedMessage id={intl.riskMonitoringNew('delete-items')} />
          </ButtonDestructive>
        )}
      />
      <Hide when={!extractInternalIds(rows).length}>
        <ActionDialog
          title={
            <FormattedMessage
              id={intl.riskMonitoringNew('remove-internal-id-title')}
              values={{
                count: extractInternalIds(rows).length,
              }}
            />
          }
          content={(close: () => void) => (
            <Typography>
              <FormattedMessage
                id={intl.riskMonitoringNew('remove-internal-id-description')}
                values={{
                  count: extractInternalIds(rows).length,
                }}
              />
            </Typography>
          )}
          maxWidth="sm"
          fullWidth={true}
          action={() => removeInternalIds(extractLocalIds(rows))}
          agreeText={<FormattedMessage id={intl.confirmAction('delete')} />}
          danger
          render={(open: () => void) => (
            <ButtonDestructive
              data-cy="remove-internal-id-selected"
              startIcon={<FontAwesomeIcon icon={faEraser as IconProp} />}
              onClick={open}
            >
              <FormattedMessage id={intl.riskMonitoringNew('remove-internal-id')} />
            </ButtonDestructive>
          )}
        />
      </Hide>
    </Stack>
  )
}

export default RowActions
