/* eslint-disable require-yield */
import { take } from 'ramda'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import {
  monitorAddToList,
  monitorGetListById,
  monitorRenameList,
  monitorShowList,
  monitorUnsubscribe,
  mostRelevantList,
} from 'services/api'
import {
  renameMonitoringList,
  saveAllListsFailed,
  saveAllListsSuccess,
  saveDataForListSuccess,
  saveEntriesForListFailed,
  saveEntriesForListSuccess,
  saveRelevantChangesFailed,
  saveRelevantChangesSuccess,
  serverError,
  setListLoading,
  setText,
  submitAddToMonitoringList,
  submitFetchListDataById,
  submitRemoveCompanyFromRiskMonitoringList,
  submitUpdateListsRiskAssesmentByID,
  updateListsRiskAssesmentSuccess,
} from './actions'
import {
  SUBMIT_ADD_TO_MONITORING_LIST,
  SUBMIT_EDIT_MONITORING_LIST,
  SUBMIT_FETCH_ALL_MONITORING_LISTS,
  SUBMIT_FETCH_LIST_DATA_BY_ID,
  SUBMIT_FETCH_LIST_ENTRIES_BY_ID,
  SUBMIT_GET_RELEVANT_CHANGES_FOR_LIST,
  SUBMIT_REMOVE_COMPANY_FROM_MONITORING_LIST,
  SUBMIT_SHOW_ALL_MONITORING_LISTS,
  SUBMIT_UPDATE_LISTS_RISK_ASSESMENT_BY_ID,
} from './constants'

/**********
 * Watcher *
//  **********/
export function* riskMonitoringSaga() {
  yield takeEvery(SUBMIT_FETCH_ALL_MONITORING_LISTS, submitFetchAllMonitoringLists)
  yield takeEvery(SUBMIT_SHOW_ALL_MONITORING_LISTS, submitShowAllMonitoringLists)
  yield takeEvery(SUBMIT_GET_RELEVANT_CHANGES_FOR_LIST, getRelevantChangesForList)
  yield takeEvery(SUBMIT_FETCH_LIST_ENTRIES_BY_ID, fetchListEntriesById)
  yield takeEvery(SUBMIT_FETCH_LIST_DATA_BY_ID, fetchListDataById)
  yield takeEvery(SUBMIT_ADD_TO_MONITORING_LIST, addToMonitoringList)
  yield takeEvery(SUBMIT_REMOVE_COMPANY_FROM_MONITORING_LIST, removeFromMonitoringList)
  yield takeEvery(SUBMIT_UPDATE_LISTS_RISK_ASSESMENT_BY_ID, updateListsRiskAssesmentById)
  yield takeEvery(SUBMIT_EDIT_MONITORING_LIST, editMonitoringList)
}

/**********
 * Worker *
 **********/
function* submitFetchAllMonitoringLists({ payload }) {
  const { listsLength } = payload
  if (listsLength === 0) {
    // yield put(setLoading(false))
  }
  // try {
  //   const result = yield call(monitorShowList, listsLength)
  //   yield put(action1Success(result))
  // } catch (error) {
  //   yield put(action1Failed())
  // }
}
// Get all lists without entries and replace the old ones
function* submitShowAllMonitoringLists() {
  try {
    const result = yield call(monitorShowList)
    yield put(saveAllListsSuccess(result))
  } catch (error) {
    yield put(saveAllListsFailed())
  }
}
function* getRelevantChangesForList({ payload }) {
  try {
    const result = yield call(mostRelevantList, payload)
    yield put(saveRelevantChangesSuccess({ listId: payload, changes: result }))
  } catch (error) {
    console.log('failed to save relevant changes for:', payload)
    yield put(saveRelevantChangesFailed())
  }
}
// Fetch the entries for a specific list
function* fetchListEntriesById({ payload }) {
  const { listId, currency } = payload

  try {
    const result = yield call(monitorGetListById, listId, currency)

    yield all([
      put(
        saveEntriesForListSuccess({
          listId,
          entries: result.monitor_list,
          count: result.count,
        })
      ),
      put(setListLoading({ listId, isLoading: false })),
    ])
  } catch (error) {
    console.log('failed to save entries for:', listId)
    yield put(saveEntriesForListFailed({ listId }))
  }
}
// Fetch the entries for a specific list
function* fetchListDataById({ payload }) {
  const { listId, currency } = payload
  try {
    const result = yield all({
      entries: safe(call(monitorGetListById, listId, currency)),
      changes: safe(call(mostRelevantList, listId)),
    })
    yield all([
      put(
        saveDataForListSuccess({
          listId,
          entries: result.entries.monitor_list,
          changes: result.changes,
          count: result.entries.count,
        })
      ),
      put(setListLoading({ listId, isLoading: false })),
    ])
  } catch (error) {
    console.log('failed to save entries for:', listId)
    yield put(saveEntriesForListFailed({ listId }))
  }
}

function* removeFromMonitoringList({ payload }) {
  const { listId, currency, entries, isMoving, refetch } = payload
  const tobeDeleted = take(500, entries)
  try {
    yield put(setListLoading({ listId, isLoading: true }))
    yield call(monitorUnsubscribe, listId, ...tobeDeleted)

    const remainingCompanies = entries.filter((el) => !tobeDeleted.includes(el))
    if (remainingCompanies.length) {
      yield put(submitRemoveCompanyFromRiskMonitoringList(listId, remainingCompanies))
    } else {
      yield all([
        put(setText(!isMoving ? 'items-deleted' : '')),
        put(submitFetchListDataById(listId, currency)),
        put(submitUpdateListsRiskAssesmentByID(listId)),
      ])
      refetch?.()
    }
  } catch (error) {
    yield all([
      put(setListLoading({ listId, isLoading: false })),
      put(serverError(error)),
    ])
  }
}
function* addToMonitoringList({ payload }) {
  const { listId, currency, entries, text, textFail, refetch } = payload
  const takeAmount = 500
  const textFailed = textFail

  const tobeAdded = take(takeAmount, entries)
  try {
    yield put(setListLoading({ listId, isLoading: true }))

    yield call(monitorAddToList, listId, tobeAdded)
    const remainingCompanies = entries.filter((el) => !tobeAdded.includes(el))
    if (remainingCompanies.length) {
      yield put(submitAddToMonitoringList(listId, remainingCompanies, text, textFailed))
    } else {
      yield all([
        put(submitFetchListDataById(listId, currency)),
        put(submitUpdateListsRiskAssesmentByID(listId)),
        put(setText(text)),
      ])
      refetch?.()
    }
  } catch (error) {
    yield all([
      put(serverError(error)),
      put(setListLoading({ listId, isLoading: false })),
      put(setText(textFailed)),
    ])
  }
}
function* updateListsRiskAssesmentById({ payload }) {
  const { listId } = payload
  try {
    const result = yield call(monitorShowList)
    yield put(
      updateListsRiskAssesmentSuccess(
        result.filter((x) => x.list_id === listId),
        listId
      )
    )
  } catch (error) {
    console.log('failed to update list:', listId)
    // yield put(saveAllListsFailed())
  }
}
function* editMonitoringList({ payload }) {
  const { listId, listName } = payload
  try {
    yield call(monitorRenameList, +listId, listName)

    yield put(renameMonitoringList(+listId, listName))
  } catch (error) {
    console.log('failed to rename list:', listId)
    // yield put(saveAllListsFailed())
  }
}

// Handles error for non crucial endpoints
function* safe(effect) {
  try {
    return yield effect
  } catch (err) {
    return null
  }
}
